import Splide from '@splidejs/splide';

const arrowLeft = document.querySelector(".multipleQuotes__arrow-left")
const arrowRight = document.querySelector(".multipleQuotes__arrow-right")
let elms = document.getElementsByClassName( 'multipleQuotes' );

if (elms.length > 0) {
    for (var i = 0; i < elms.length; i++) {

        const splide = new Splide(elms[i], {
            type: 'loop',
            perPage: 1,
            easing: 'linear',
            //rewind: true,
            pagination: false,
            arrows: true,
            lazyLoad: 'nearby',
        });

        splide.mount();
    }
}